import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import Dotdotdot from "components/Common/Dotdotdot";
import EntityImage from "components/Entities/EntityImage";
import EntityLink from "components/Entities/EntityLink";
import EntityFollowSimpleButton from "components/Follow/EntityFollowSimpleButtonAsync";
import BadgeContainer from "components/Tastemaker/BadgeContainer";
import CreatorDescription from "pages/CreatorView/CreatorDescriptionAsync";

import getCreatorName from "utils/entity/getCreatorName";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import { equals } from "utils/misc";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  personFollowCard: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "1rem",
    paddingBottom: "0.75rem",

    [ScreenSizes.lgAndAbove]: {
      paddingRight: "0.75rem",
      paddingBottom: 0,
      marginBottom: "1.5rem",
    },
  },
  entityImage: {
    width: "4.75rem",
    minWidth: "4.75rem",
    height: "4.75rem",
    marginRight: "1rem",
    marginLeft: "0.5rem",
    position: "relative",
  },
  creatorPodcastImage: {
    position: "absolute",
    bottom: -1,
    right: -1,
    width: 30,
    minWidth: 30,
    height: 30,
    border: "1px solid #fff",
  },
  personInfo: {
    minWidth: 0, // Fixes truncation issue
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  personName: {},
  personDescription: {
    fontSize: ".825rem",
    lineHeight: "1.5",
    [ScreenSizes.mdAndAbove]: {
      fontSize: ".75rem",
      lineHeight: "1.2",
    },
  },
  username: {
    ...gStyles.avalonRegular,
    ...gStyles.textEllipsis,
    color: "#999",
    fontSize: "0.8125rem",
    marginTop: "0.25rem",
  },
  buttonContainer: {
    width: "6.25rem",
    marginTop: ".75rem",
  },
};

const followButtonStyles = {
  button: {
    padding: ".4rem 0",
  },
};

const entityLinkStyles = {
  profileLinkContainer: {
    display: "inline",
  },
};

const entityNameLinkStyles = {
  entityLink: {
    ...gStyles.avalonBold,
    display: "block",
    fontSize: "1rem",
    color: colours.bodyText,
    marginBottom: ".375rem",
  },
};

const badgeContainerStyles = {
  badgeContainer: {
    left: "-0.5rem",
    bottom: "-0.375rem",
  },
  badge: {
    width: "2.125rem",
    height: "2.125rem",
  },
};

const PersonFollowCard = (props) => {
  const {
    person,
    entity_type,
    externalLink,
    disablePopup,
    renderEntityImageOverlay,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const loggedInUser = useLoggedInUser();
  const isLoggedInUser =
    entity_type === "user" &&
    loggedInUser &&
    equals(loggedInUser.get("id"), person.get("id"));

  const podcast =
    entity_type === "creator" &&
    person &&
    person.getIn(["most_recent_episode", "podcast"]);

  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);

  return (
    <div
      data-id="person-follow-card"
      data-value={person.get("id")}
      key={person.get("id")}
      className={css(styles.personFollowCard)}
    >
      <div className={css(styles.entityImage)}>
        <BadgeContainer
          styles={badgeContainerStyles}
          userId={person?.get("id")?.toString()}
        >
          <EntityImage
            entity={person}
            entity_type={entity_type}
            size={76}
            externalLink={externalLink}
            disablePopup={disablePopup}
            renderEntityImageOverlay={renderEntityImageOverlay}
            onClick={handleStopPropagation}
            showTitle
          />
        </BadgeContainer>

        {entity_type === "creator" && podcast && (
          <EntityImage
            entity_type="podcast"
            entity={podcast}
            size={28}
            showTitle
            fullWidth={false}
            className={css(styles.creatorPodcastImage)}
          />
        )}
      </div>
      <div className={css(styles.personInfo)}>
        <EntityLink
          styles={entityNameLinkStyles}
          entity={person}
          entity_type={entity_type}
          disablePopup={disablePopup}
          externalLink={externalLink}
        >
          {entity_type === "creator" ? (
            getCreatorName(person)
          ) : (
            <Fragment>
              <Dotdotdot clamp={1} className={css(styles.personName)}>
                {getUserDisplayName(person, entity_type)}
              </Dotdotdot>
              <Dotdotdot clamp={1} className={css(styles.username)}>
                {`@${person.get("username")}`}
              </Dotdotdot>
            </Fragment>
          )}
        </EntityLink>
        <Dotdotdot
          clamp={mobile ? 3 : 2}
          tagName="div"
          className={css(styles.personDescription)}
        >
          {entity_type === "creator" ? (
            <CreatorDescription
              creator={person}
              maxItems={mobile ? 3 : 1}
              stringLen={50}
              disablePopup={disablePopup}
              externalLink={externalLink}
              entityLinkStyles={entityLinkStyles}
            />
          ) : (
            person.get("bio") || person.get("description")
          )}
        </Dotdotdot>
        <div className={css(styles.buttonContainer)}>
          {!isLoggedInUser && (
            <EntityFollowSimpleButton
              entity={person}
              entity_type={entity_type}
              customStyles={followButtonStyles}
              variation="white"
              fontSize=".75rem"
              // subscribe
              flat
            />
          )}
        </div>
      </div>
    </div>
  );
};

PersonFollowCard.propTypes = {
  person: PropTypes.instanceOf(Map).isRequired,
  entity_type: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  disablePopup: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
};

PersonFollowCard.defaultProps = {
  externalLink: false,
  disablePopup: false,
  renderEntityImageOverlay: null,
};

export default memo(PersonFollowCard);

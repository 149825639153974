import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ProfileHoverPopover from "components/Profiles/ProfileHoverPopover";

import UserProfileHoverCard from "./UserProfileHoverCard";

const UserProfileHoverPopover = (props) => {
  const { user, children, mobile, inline, expand } = props;

  const renderContent = useCallback(
    (popoutProps) => (
      <UserProfileHoverCard
        user={user}
        user_id={user.get("id")}
        username={user.get("username")}
        mobile={mobile}
        popoutProps={popoutProps}
      />
    ),
    [user, mobile]
  );

  return (
    <ProfileHoverPopover
      renderContent={renderContent}
      mobile={mobile}
      inline={inline}
      expand={expand}
      ariaLabel="Show User Profile Details"
    >
      {children}
    </ProfileHoverPopover>
  );
};

UserProfileHoverPopover.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  children: PropTypes.node.isRequired,
  mobile: PropTypes.bool,
  inline: PropTypes.bool,
  expand: PropTypes.bool,
};

UserProfileHoverPopover.defaultProps = {
  mobile: false,
  inline: false,
  expand: false,
};

export default UserProfileHoverPopover;

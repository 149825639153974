import pluralize from "pluralize";

import addMetricPostfix from "../../../../utils/misc/addMetricPostfix";

import getUserListUrl from "utils/entity/getUserListUrl";
import { capitalize } from "utils/misc";

export const getIcons = (list, options = null) => {
  if (!list) {
    return [];
  }

  return [
    (list.get("like_count") || (options && options.showLike)) && {
      type: "liked",
      value: list.get("like_count"),
      liked: list.getIn(["user_data", "is_liked"]),
      submitting: list.getIn(["user_data", "like_saving"]),
      text: `${addMetricPostfix(list.get("like_count"))} ${pluralize(
        capitalize("Like"),
        list.get("like_count")
      )}`,
      mobileText: `${addMetricPostfix(list.get("like_count"))}`,
      entity: list,
    },
    list.get("follower_count") && {
      type: "followers",
      text: `${addMetricPostfix(list.get("follower_count"))} ${pluralize(
        capitalize("Follower"),
        list.get("follower_count")
      )}`,
      mobileText: addMetricPostfix(list.get("follower_count")),
      to: getUserListUrl(list),
      hideOnMobile: true,
      hideOnCompact: true,
    },
    list.get("item_count") && {
      type: "listItems",
      text: `${addMetricPostfix(list.get("item_count"))} ${pluralize(
        capitalize(list.get("entity_type")),
        list.get("item_count")
      )}`,
      mobileText: `${addMetricPostfix(list.get("item_count"))} ${pluralize(
        capitalize(list.get("entity_type")),
        list.get("item_count")
      )}`,
      to: getUserListUrl(list),
      entityType: list.get("entity_type"),
      hideOnMobile: true,
      hideOnCompact: true,
    },
    list.get("updated_at") && {
      type: "updated",
      value: list.get("updated_at"),
      to: getUserListUrl(list),
    },
  ].filter((iconType) => iconType);
};

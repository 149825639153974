import { Map } from "immutable";
import PropTypes from "prop-types";

import EntityOverviewTemplate from "../EntityOverviewTemplate";

const CreatorOverview = (props) => {
  const { creator } = props;

  return (
    <EntityOverviewTemplate
      dataId="creator-overview"
      {...props}
      entity_type="creator"
      entity={creator}
      subtitle={creator.get("subtitle_short")}
    />
  );
};

CreatorOverview.propTypes = {
  creator: PropTypes.instanceOf(Map).isRequired,
};

export default CreatorOverview;

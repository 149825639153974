import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ProfileHoverPopover from "components/Profiles/ProfileHoverPopover";

import ListProfileHoverCard from "./ListProfileHoverCard";

const ListProfileHoverPopover = (props) => {
  const { list, children, mobile, inline, expand } = props;

  const renderContent = useCallback(
    (popoutProps) => (
      <ListProfileHoverCard
        list={list}
        mobile={mobile}
        popoutProps={popoutProps}
      />
    ),
    [list, mobile]
  );

  return (
    <ProfileHoverPopover
      renderContent={renderContent}
      mobile={mobile}
      inline={inline}
      expand={expand}
      ariaLabel="Show List details"
    >
      {children}
    </ProfileHoverPopover>
  );
};

ListProfileHoverPopover.propTypes = {
  list: PropTypes.instanceOf(Map).isRequired,
  children: PropTypes.node.isRequired,
  mobile: PropTypes.bool,
  inline: PropTypes.bool,
  expand: PropTypes.bool,
};

ListProfileHoverPopover.defaultProps = {
  mobile: false,
  inline: false,
  expand: false,
};

export default ListProfileHoverPopover;

import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import StandardButton from "components/Buttons/StandardButton";
import Dotdotdot from "components/Common/Dotdotdot";
import { getIcons } from "components/Entities/Cards/ListCards/sharedListElements";
import EntityImage from "components/Entities/EntityImage";
import EntityLink from "components/Entities/EntityLink";
import InfoIcons from "components/Entities/Items/InfoIconsAsync";

import HoverCardFollowButton from "./HoverCardFollowButton";
import profileHoverCardStyles from "./ProfileHoverCardStyles";
import ProfileHoverPopoverContent from "./ProfileHoverPopoverContent";

import getUserListUrl from "utils/entity/getUserListUrl";
import { capitalize } from "utils/misc";
import commaList from "utils/text/commaList";

import useListOwner from "hooks/useListOwner";
import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const mobileImageSize = "6rem";
const desktopImageSize = "6rem";

const baseStyles = {
  ...profileHoverCardStyles,
  content: {
    ...profileHoverCardStyles.content,
    textAlign: "center",
  },
  topInfoRow: {
    ...profileHoverCardStyles.topInfoRow,
    flexDirection: "column",
  },
  profileImage: {
    ...profileHoverCardStyles.profileImage,
    marginBottom: "1rem",
    width: mobileImageSize,
    height: mobileImageSize,

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.profileImage[ScreenSizes.lgAndAbove],
      marginBottom: ".75rem",
      width: desktopImageSize,
      height: desktopImageSize,
    },
  },
  names: {
    marginBottom: "1rem",
  },
  name: {
    ...profileHoverCardStyles.name,
    marginBottom: ".75rem",
    display: "block",

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.name[ScreenSizes.lgAndAbove],
      marginBottom: ".5rem",
      fontSize: "1rem",
    },
  },
};

const ListProfileHoverCard = (props) => {
  const { list, mobile, width, popoutProps } = props;
  const { styles } = useStyles(baseStyles, props);
  const displayName = list.get("title");
  const listUrl = getUserListUrl(list);
  const owner = useListOwner(list);

  const icons = useMemo(() => getIcons(list), [list]);

  const renderDescription = () => (
    <span>
      <span className={css(styles.descriptionDescription)}>
        {`"${capitalize(list.get("description"))}"`}
      </span>
    </span>
  );

  const generateListDescriptionFromSummaryItems = useMemo(() => {
    if (!list?.get("preview_entities")) {
      return null;
    }

    const titleList = commaList(
      list.get("preview_entities").slice(0, 3),
      (e, index) => (
        <EntityLink
          key={index}
          entity_type={list.get("entity_type")}
          entity={e}
          inline
          disablePopup
        />
      ),
      { skipConjunction: list.get("item_count") > 3 }
    );

    const additional =
      list.get("item_count") > 3
        ? ` and ${list.get("item_count") - 3} others`
        : "";

    return (
      <div>
        {owner && "Featuring "}
        {!owner && `${capitalize(list.get("entity_type"))} list featuring `}
        {titleList}
        {additional}
      </div>
    );
  }, [list, owner]);

  const renderInfo = () => (
    <span className={css(styles.topInfoRow)}>
      <EntityImage
        entity={list}
        entity_type="userlist"
        alt={displayName}
        size={width}
        className={css(styles.profileImage)}
        disablePopup
        fullWidth
      />
      <Link className={css(styles.namesColumn)} to={listUrl}>
        <span className={css(styles.name)}>{displayName}</span>
      </Link>

      {owner && (
        <span className={css(styles.subtitle)}>
          {`${capitalize(list.get("entity_type"))} list by`}{" "}
          <EntityLink
            entity_type="user"
            entity={owner}
            className={css(styles.subtitleLink)}
            disablePopup
          />
        </span>
      )}

      <span className={css(styles.infoIcons)}>
        <InfoIcons icons={icons} mobile />
      </span>
      <span className={css(styles.description)}>
        {list.get("description") ? (
          <Dotdotdot clamp={3}>{renderDescription()}</Dotdotdot>
        ) : (
          generateListDescriptionFromSummaryItems
        )}
      </span>
    </span>
  );

  return (
    <ProfileHoverPopoverContent popoutProps={popoutProps}>
      <span className={css(styles.content, mobile && styles.mobileContent)}>
        {renderInfo()}
        <div className={css(styles.buttonContainer)}>
          <HoverCardFollowButton
            name="List"
            entity={list}
            entity_type="userlist"
          />
        </div>
        {mobile && (
          <StandardButton
            label="View List"
            variation="white"
            to={listUrl}
            link
            flat
          />
        )}
      </span>
    </ProfileHoverPopoverContent>
  );
};

ListProfileHoverCard.propTypes = {
  list: PropTypes.instanceOf(Map).isRequired,
  popoutProps: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  width: PropTypes.number,
};

ListProfileHoverCard.defaultProps = {
  mobile: false,
  width: 80,
};

export default memo(ListProfileHoverCard);

import { css } from "aphrodite";
import { Map, List } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { loadSpecificUser as loadSpecificUserRountine } from "routines/user";

import StandardButton from "components/Buttons/StandardButton";
import Dotdotdot from "components/Common/Dotdotdot";
import LoadingOverlay from "components/Common/LoadingOverlay";
import EntityImage from "components/Entities/EntityImage";
import BadgeContainer from "components/Tastemaker/BadgeContainer";
import Top8Podcasts from "components/Users/Top8Podcasts/Top8PodcastsAsync";

import HoverCardFollowButton from "./HoverCardFollowButton";
import profileHoverCardStyles from "./ProfileHoverCardStyles";
import ProfileHoverPopoverContent from "./ProfileHoverPopoverContent";

import { selectSpecificPodcast, selectPodcastLoading } from "selectors/podcast";
import { selectSpecificUser, selectUserLoading } from "selectors/user";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import getUserUrl from "utils/entity/getUserUrl";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const mobileImageSize = "3.75rem";
const desktopImageSize = "2.75rem";

const baseStyles = {
  ...profileHoverCardStyles,
  profileImage: {
    ...profileHoverCardStyles.profileImage,
    width: mobileImageSize,
    height: mobileImageSize,
    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.profileImage[ScreenSizes.lgAndAbove],
      width: desktopImageSize,
      height: desktopImageSize,
    },
  },
  loadingContent: {
    minHeight: "70vh",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "21rem",
    },
  },
  username: {
    ...gStyles.textEllipsis,
    ...gStyles.fontSemiBold,
    fontSize: "1rem",
    color: "#a3a3a3",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".688rem",
    },
  },
  bio: {
    ...gStyles.fontMedium,
    color: "#343434",
    fontSize: "1rem",
    lineHeight: "1.625rem",
    marginTop: "1.25rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: ".75rem",
      fontSize: ".688rem",
      lineHeight: "16px",
    },
  },
  followCounts: {
    display: "flex",
    flexDirection: "row",
    color: "#343434",
    width: "100%",
    marginTop: "1rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: ".75rem",
    },
  },
  countColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  countValue: {
    ...gStyles.fontSemiBold,
    fontSize: "1.4rem",
    marginBottom: ".2rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1rem",
      marginBottom: ".1rem",
    },
  },
  countTitle: {
    ...gStyles.fontMedium,
    fontSize: "1rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".75rem",
    },
  },
  top8: {
    margin: "-5px -5px 1.4rem",
    width: "calc(100% + 8px)",

    [ScreenSizes.lgAndAbove]: {
      margin: "-4px -4px .5rem",
    },
  },
};

const top8SquareStyles = {
  gridSquareEmptyItem: {
    backgroundColor: colours.lightGrey,
  },
  gridSquareIsBeingDragged: {
    backgroundColor: colours.lightGrey,
  },
  gridSquareHasItem: {
    backgroundColor: colours.lightGrey,
  },
  gridSquareItemIsOver: {
    backgroundColor: colours.lightGrey,
  },
  gridSquareInnerItemIsOver: {
    opacity: 0, // '0.5',
  },

  addItemIcon: {
    fontSize: "3rem",
    opacity: ".4",
  },
  indexBadge: {
    ...gStyles.fontSemiBold,
    fontSize: ".75rem",
    width: "1rem",
    height: "1rem",
  },
  indexBadgeWithoutItem: {
    display: "none",
  },
};

const top8Styles = {
  top8Podcasts: {
    backgroundColor: "transparent",
    borderRadius: 0,
    boxShadow: "none",
    padding: 0,
  },
  titleContainer: {
    display: "none",
  },
};

const badgeContainerStyles = {
  badgeContainer: {
    left: "-0.25rem",
    bottom: "-0.25rem",
  },
  badge: {
    width: "1.25rem",
    height: "1.25rem",
  },
  container: {
    width: `calc(${mobileImageSize} + 0.5rem)`,
    height: `calc(${mobileImageSize} + 0.5rem)`,

    [ScreenSizes.lgAndAbove]: {
      width: `calc(${desktopImageSize} + 0.5rem)`,
      height: `calc(${desktopImageSize} + 0.5rem)`,
    },
  },
};

const UserProfileHoverCard = (props) => {
  const { user: passedUser, user_id, username, mobile, popoutProps } = props;
  const { styles } = useStyles(baseStyles, props);
  const firstLoad = useRef(false);
  const authUser = useLoggedInUser();
  const loadedUser = useReduxState(
    (state) => selectSpecificUser(state, user_id),
    [user_id]
  );
  const entityLoading = useReduxState(
    (state) => selectUserLoading(state, user_id),
    [user_id]
  );
  const user = loadedUser || passedUser;
  const loading = !user || !user.get("full_record") || entityLoading;
  const displayName = getUserDisplayName(user);
  const { loadSpecificUser } = useActionCreators({
    loadSpecificUser: loadSpecificUserRountine,
  });
  const userUrl = getUserUrl(user);

  const topPodcastsIds = useMemo(
    () =>
      user && user.get("top_podcasts") ? user.get("top_podcasts") : List([]),
    [user]
  );
  const topPodcasts = useReduxState(
    (state) =>
      topPodcastsIds.map((podcast_id) => {
        if (selectPodcastLoading(state, podcast_id)) {
          return "loading";
        }

        return selectSpecificPodcast(state, podcast_id);
      }),
    [topPodcastsIds]
  );

  if (!entityLoading && !user.get("full_record") && !firstLoad.current) {
    loadSpecificUser({ user_id: username });
    firstLoad.current = true;
  }

  const renderInfo = useCallback(
    () => (
      <div className={css(styles.topInfoRow)}>
        <BadgeContainer
          styles={badgeContainerStyles}
          userId={user?.get("id")}
          disableTooltip
        >
          <EntityImage
            className={css(styles.profileImage)}
            size={mobile ? mobileImageSize : desktopImageSize}
            entity_type="user"
            entity={user}
            cacheSize={120}
            fullWidth
            profileImage
          />
        </BadgeContainer>
        <Link
          data-id="user-profile-hover-card-info"
          className={css(styles.namesColumn)}
          to={userUrl}
        >
          <div className={css(styles.name)}>{displayName}</div>
          <div className={css(styles.username)}>
            {`@${user.get("username")}`}
          </div>
        </Link>
      </div>
    ),
    [
      styles.topInfoRow,
      styles.profileImage,
      styles.namesColumn,
      styles.name,
      styles.username,
      user,
      mobile,
      userUrl,
      displayName,
    ]
  );

  const renderFollowCounts = useCallback(
    () => (
      <div className={css(styles.followCounts)}>
        <div className={css(styles.countColumn)}>
          <div className={css(styles.countValue)}>
            {user.get("follower_count")}
          </div>
          <div className={css(styles.countTitle)}>followers</div>
        </div>
        <div className={css(styles.countColumn)}>
          <div className={css(styles.countValue)}>
            {user.get("follow_count")}
          </div>
          <div className={css(styles.countTitle)}>following</div>
        </div>
      </div>
    ),
    [styles, user]
  );

  const renderTop8 = useCallback(() => {
    if (topPodcasts.includes("loading")) {
      return (
        <LoadingOverlay
          noOverlay
          noPadding
          key="userProfileHoverCardLoadingOverlay"
        />
      );
    }

    return (
      <div className={css(styles.top8)}>
        <Top8Podcasts
          user={user}
          styles={top8Styles}
          squareStyles={top8SquareStyles}
          top_podcasts={topPodcasts}
          itemsPerRow={4}
          itemPadding={mobile ? 4 : 5}
          outerPadding={0}
          width={mobile ? undefined : 232}
          hideShare
          fullWidth={mobile}
        />
      </div>
    );
  }, [user, topPodcasts, styles.top8, mobile]);

  if (loading) {
    return (
      <div className={css(styles.content, styles.loadingContent)}>
        <LoadingOverlay
          noOverlay
          noPadding
          key="userProfileHoverCardLoadingOverlay"
        />
      </div>
    );
  }

  return (
    <ProfileHoverPopoverContent popoutProps={popoutProps}>
      <div className={css(styles.content, mobile && styles.mobileContent)}>
        {renderInfo()}
        {user.get("bio") && (
          <div className={css(styles.bio)}>
            <Dotdotdot clamp={4}>{user.get("bio")}</Dotdotdot>
          </div>
        )}
        {renderFollowCounts()}
        <div className={css(styles.divider)} />
        {user.get("top_podcasts") &&
          user.get("top_podcasts").size > 0 &&
          renderTop8()}
        {(!authUser || authUser.get("id") !== user.get("id")) && (
          <div className={css(styles.buttonContainer)}>
            <HoverCardFollowButton
              name={displayName}
              entity={user}
              entity_type="user"
            />
          </div>
        )}
        {mobile && (
          <StandardButton
            label="View Profile"
            variation="white"
            to={userUrl}
            link
            flat
          />
        )}
      </div>
    </ProfileHoverPopoverContent>
  );
};

UserProfileHoverCard.propTypes = {
  popoutProps: PropTypes.object.isRequired,
  user: PropTypes.instanceOf(Map),
  user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  username: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
};

UserProfileHoverCard.defaultProps = {
  user: null,
  mobile: false,
};

export default memo(UserProfileHoverCard);

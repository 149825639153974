import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const CreatorDescription = Loadable(() => import("./CreatorDescription"));

function CreatorDescriptionAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const CreatorDescriptionContainer = (props) => {
  return (
    <CreatorDescription
      {...props}
      fallback={<CreatorDescriptionAsyncLoading />}
    />
  );
};

export default CreatorDescriptionContainer;

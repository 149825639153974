import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";

import BookmarkButton from "components/Buttons/BookmarkButton";

import EntityOverviewTemplate from "../EntityOverviewTemplate";

import { selectSpecificPodcast } from "selectors/podcast";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";

const baseStyles = {
  bookmarkButton: {
    position: "absolute",
    top: 0,
    right: ".75rem",
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bookmarkSpacer: {
    width: "2.75rem",
    height: "100%",
  },
};

const templateStyles = {
  entityTitle: {
    fontSize: ".875rem",
  },
  entitySubtitle: {
    ...cardStyles.episodePodcastName,
    fontSize: "0.8125rem",
  },
};

const bookmarkButtonStyles = {
  bookmarkButton: {
    fontSize: ".875rem",
    color: "var(--color-neutral-d6)",
  },
};

const EpisodeOverview = (props) => {
  const { episode } = props;
  const { styles } = useStyles(baseStyles, props);
  const podcastId = episode.get("podcast_id");
  const pulledPodcast = useReduxState(
    (state) => selectSpecificPodcast(state, podcastId),
    [podcastId]
  );
  const podcast = podcastId ? pulledPodcast : episode.get("podcast");

  return (
    <EntityOverviewTemplate
      dataId="episode-overview"
      {...props}
      styles={templateStyles}
      entity_type="episode"
      entity={episode}
      subtitle_entity_type="podcast"
      subtitle_entity={podcast}
      subtitleAboveTitle
    >
      <div className={css(styles.bookmarkSpacer)} />
      <div className={css(styles.bookmarkButton)}>
        <BookmarkButton
          entity_type="episode"
          entity={episode}
          styles={bookmarkButtonStyles}
        />
      </div>
    </EntityOverviewTemplate>
  );
};

EpisodeOverview.propTypes = {
  episode: PropTypes.instanceOf(Map).isRequired,
};

export default EpisodeOverview;

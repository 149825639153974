import { Map } from "immutable";
import PropTypes from "prop-types";

import EntityOverviewTemplate from "../EntityOverviewTemplate";

const ListOverview = (props) => {
  const { list } = props;

  return (
    <EntityOverviewTemplate
      dataId="list-overview"
      {...props}
      entity_type="userlist"
      entity={list}
      subtitle={list.get("description")}
    />
  );
};

ListOverview.propTypes = {
  list: PropTypes.instanceOf(Map).isRequired,
};

export default ListOverview;

import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const EntityFollowSimpleButton = Loadable(() =>
  import("./EntityFollowSimpleButton")
);

function EntityFollowSimpleButtonAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const EntityFollowSimpleButtonContainer = (props) => {
  return (
    <EntityFollowSimpleButton
      {...props}
      fallback={<EntityFollowSimpleButtonAsyncLoading />}
    />
  );
};

export default EntityFollowSimpleButtonContainer;

import { Map } from "immutable";
import PropTypes from "prop-types";

import EntityOverviewTemplate from "../EntityOverviewTemplate";

const overviewStyles = {
  entitySubtitle: {
    color: "#BBB",
  },
};

const UserOverview = (props) => {
  const { user } = props;

  return (
    <EntityOverviewTemplate
      dataId="user-overview"
      {...props}
      entity_type="user"
      entity={user}
      subtitle={`@${user.get("username")}`}
      styles={overviewStyles}
    />
  );
};

UserOverview.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
};

export default UserOverview;

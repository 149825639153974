import { faBookmark } from "@fortawesome/free-regular-svg-icons/faBookmark";
import { faBookmark as faBookmarkActioned } from "@fortawesome/free-solid-svg-icons/faBookmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useContext, forwardRef } from "react";
import { setBookmarkedPromise } from "routines/bookmarks";

import ActivityContext from "components/Analytics/ActivityContext";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import sendGAEvent from "utils/sendGAEvent";

import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  bookmarkButton: {
    cursor: "pointer",
    color: "var(--color-neutral-d6)",
    height: "100%",
    fontSize: ".875rem",
    maxWidth: ".75em",
  },
  bookmarkButtonIsBookmarked: {
    color: colours.actioned,
  },
};

const BookmarkButton = (props) => {
  const { entity, entity_type } = props;
  const { styles } = useStyles(baseStyles, props);

  const { setBookmarked } = useRoutinePromises({
    setBookmarked: setBookmarkedPromise,
  });

  const activityContext = useContext(ActivityContext) || {};

  const bookmarked = entity && entity.getIn(["user_data", "bookmarked"]);

  const handleBookmark = useCallback(() => {
    setBookmarked({
      entity,
      entity_type,
      entity_id: entity.get("id"),
      bookmark: !bookmarked,
    });
    sendGAEvent({
      action: bookmarked ? "entityBookmarkRemoved" : "entityBookmarkAdded",
      entity_type,
      entity_id: entity.get("id"),
      entity_name: entity && (entity.get("title") || entity.get("name")),
      ...activityContext,
    });
  }, [setBookmarked, entity_type, entity, bookmarked, activityContext]);

  const renderTooltip = useCallback(() => {
    if (bookmarked) {
      return "Remove bookmark";
    }

    return "Bookmark";
  }, [bookmarked]);

  if (!entity) {
    return null;
  }

  const BookMarkIcon = forwardRef((props, ref) => {
    return (
      <FontAwesomeIcon
        {...props}
        className={css(
          styles.bookmarkButton,
          bookmarked && styles.bookmarkButtonIsBookmarked
        )}
        onClick={handleBookmark}
        icon={bookmarked ? faBookmarkActioned : faBookmark}
      />
    );
  });

  return (
    <BasicTooltip renderTooltip={renderTooltip}>
      {(tooltipProps) => <BookMarkIcon {...tooltipProps} />}
    </BasicTooltip>
  );
};

BookmarkButton.propTypes = {
  entity_type: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Map).isRequired,
};

export default memo(BookmarkButton);

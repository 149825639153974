import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";

import BookmarkButton from "components/Buttons/BookmarkButton";
import DotDotDot from "components/Common/Dotdotdot";

import CategoryTag from "../CategoryTag";
import EntityOverviewTemplate from "../EntityOverviewTemplate";

import getCategoryText from "utils/entity/getCategoryText";
import { capitalize } from "utils/misc";
import commaList from "utils/text/commaList";
import indefiniteArticle from "utils/text/indefiniteArticle";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%",
  },
  categories: {
    ...gStyles.noEllipsis,
    marginRight: ".5rem",
  },
  nbsp: {
    display: "inline-block",
    width: "0.3em",
  },
  bookmarkSpacer: {
    width: "2.75rem",
    height: "100%",
  },
};

const categoryTagStyles = {
  category: {
    display: "inline-block",
  },
  categoryAsLink: {
    display: "inline-block",
  },
  nbsp: {
    display: "inline-block",
  },
};

const bookmarkButtonStyles = {
  bookmarkButton: {
    position: "absolute",
    top: 0,
    right: ".75rem",
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "var(--color-neutral-d6)",
  },
};

const PAGE_SIZE = 3;

const PodcastOverview = (props) => {
  const { podcast, hideCategories, renderOnMount } = props; // hideCreators
  const { styles } = useStyles(baseStyles, props);

  const renderNBSP = useCallback(
    (index = "") => <span key={`nbsp${index}`} className={css(styles.nbsp)} />,
    [styles.nbsp]
  );

  const categoriesToDisplay = useMemo(
    () =>
      podcast &&
      podcast.has("categories") &&
      podcast
        .get("categories")
        .slice(0, PAGE_SIZE)
        .filter((item) => {
          const catText = getCategoryText(item);

          return catText && catText.trim() !== "";
        }),
    [podcast]
  );

  const renderCategory = useCallback(
    (category, index) => (
      <span key={category && category.get ? category.get("slug") : category}>
        {(index === categoriesToDisplay.size - 1 || index === 0) &&
          renderNBSP(index)}
        <CategoryTag slug={category} asLink styles={categoryTagStyles} />
      </span>
    ),
    [categoriesToDisplay, renderNBSP]
  );

  const renderCategories = useCallback(
    () => (
      <DotDotDot clamp={2} className={css(styles.categories)}>
        {categoriesToDisplay && categoriesToDisplay.size > 0 && (
          <Fragment>
            {capitalize(indefiniteArticle(categoriesToDisplay.get(0)))}
            {commaList(categoriesToDisplay, renderCategory, {
              conjunction: (
                <Fragment>
                  {renderNBSP("and")}
                  and
                </Fragment>
              ),
              oxfordComma: true,
            })}
            {renderNBSP()}
            podcast
          </Fragment>
        )}
      </DotDotDot>
    ),
    [
      styles.categories,
      categoriesToDisplay,
      renderCategory,
      renderNBSP,
      renderOnMount,
    ]
  );

  const subtitle = useMemo(() => {
    if (!hideCategories) {
      return <div className={css(styles.row)}>{renderCategories()}</div>;
    }

    return null;
  }, [styles.row, hideCategories, renderCategories]);

  return (
    <EntityOverviewTemplate
      dataId="podcast-overview"
      {...props}
      entity_type="podcast"
      entity={podcast}
      subtitle={subtitle}
    >
      <div className={css(styles.bookmarkSpacer)} />
      <BookmarkButton
        entity_type="podcast"
        entity={podcast}
        styles={bookmarkButtonStyles}
      />
    </EntityOverviewTemplate>
  );
};

PodcastOverview.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
  hideCategories: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

PodcastOverview.defaultProps = {
  mobile: false,
  hideCategories: false,
  renderOnMount: false,
};

export default PodcastOverview;

import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo, useRef, useState } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import EntityImage from "components/Entities/EntityImage";
import EntityFollowSimpleButton from "components/Follow/EntityFollowSimpleButtonAsync";

import { DESKTOP_ACTIONS_HEIGHT } from "constants/podcast";

import useLayoutEffect from "hooks/useLayoutEffect";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  podcastContainer: {
    width: "100%",
    marginBottom: "1.5rem",
  },
  podcastInner: {
    padding: "0 0.5rem",
  },
  podcastImage: {
    margin: "0 auto .5rem",
    maxWidth: "100%",
    backgroundColor: "rgba(0,0,0,0.04)",

    width: "100%",
    height: 0,
    paddingBottom: "100%",
  },
};

const entityImageStyles = {
  entityImage: {
    width: "100%",
    height: "auto",
  },
};

const followButtonStyles = {
  button: {
    padding: ".4rem 0",
  },
};

const PodcastFollowCard = (props) => {
  const {
    podcast,
    defaultSize,
    size: passedSize,
    externalLink,
    onClick,
    renderEntityImageOverlay,
    renderOnMount,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const [size, setSize] = useState(null);

  const imageRef = useRef(null);

  useLayoutEffect(() => {
    if (
      !passedSize &&
      imageRef.curren &&
      imageRef.current.clientWidth !== size
    ) {
      setSize(imageRef.current.clientWidth);
    }
  }, [passedSize, size]);

  const imageStyle = useMemo(() => {
    const newSize = passedSize || size || defaultSize;

    if (newSize) {
      return {
        width: newSize,
        height: newSize,
      };
    }

    return null;
  }, [size, defaultSize, passedSize]);

  return (
    <div
      data-id="podcast-follow-card"
      data-value={podcast.get("id")}
      key={podcast.get("id")}
      className={css(styles.podcastContainer)}
    >
      <div className={css(styles.podcastInner)}>
        <div
          ref={imageRef}
          className={css(styles.podcastImage)}
          style={imageStyle}
        >
          <BasicTooltip
            renderTooltip={() => podcast.get("title")}
            zIndex={10000}
            leaveTimeout={0}
          >
            {(tooltipProps) => (
              <div {...tooltipProps}>
                <EntityImage
                  entity={podcast}
                  entity_type="podcast"
                  size={size || 152}
                  externalLink={externalLink}
                  showTitle
                  styles={entityImageStyles}
                  renderEntityImageOverlay={renderEntityImageOverlay}
                />
              </div>
            )}
          </BasicTooltip>
        </div>
        <LazyLoadComponent
          renderOnMount={renderOnMount}
          height={DESKTOP_ACTIONS_HEIGHT}
        >
          <EntityFollowSimpleButton
            entity={podcast}
            entity_type="podcast"
            customStyles={followButtonStyles}
            variation="white"
            fontSize=".75rem"
            onClick={onClick}
            // subscribe
            flat
          />
        </LazyLoadComponent>
      </div>
    </div>
  );
};

PodcastFollowCard.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  defaultSize: PropTypes.number,
  size: PropTypes.number,
  externalLink: PropTypes.bool,
  onClick: PropTypes.func,
  renderEntityImageOverlay: PropTypes.func,
  renderOnMount: PropTypes.bool,
};

PodcastFollowCard.defaultProps = {
  defaultSize: null,
  size: null,
  externalLink: false,
  onClick: null,
  renderEntityImageOverlay: null,
  renderOnMount: false,
};

export default PodcastFollowCard;
